<template>
    <div id="previous_labels" class="hide-scrollbar" :class="{ landscape: ['desktop', 'landscape'].includes($mq), 'no-results': previews.length == 0 && ['desktop', 'landscape'].includes($mq) }">
        <template v-if="error">
            <div class="error">{{ error }}</div>
        </template>
        <template v-else-if="previews.length > 0">
            <div class="preview_container hide-scrollbar" :class="{ selected: selectedLabel === index }" :key="index" v-for="(preview, index) in previews" @click="sendValue(index)">
                <Preview :data="preview" :previousLabels="true" />
            </div>
        </template>
    </div>
</template>
<script>
import Preview from '@/components/domain/label/preview.vue'
export default {
    name: 'previousLabels',
    components: {
        Preview
    },
    props: {
        givenValue: { default: false },
        rules: { default: Object },
        referenceId: { default: String },
        state: { default: Object }
    },
    data() {
        return {
            related_states: [],
            error: false,
            previews: [],
            labels: [],
            selectedLabel: 0,
            other_products: []
        }
    },
    computed: {},
    methods: {
        load() {
            const self = this

            if (!fnCheckConnection()) {
                self.error = self.$t('label.previous_labels.network_error')
                return true
            }

            this.$overlay.loading()

            this.$store
                .dispatch('label/loadPrintedRelatedStates', { reference_id: this.referenceId, states: this.related_states, other_products: this.other_products })
                .then((response) => {
                    let previews = []
                    let labels = []
                    for (let key in response) {
                        let label = response[key]
                        label.product = label.reference
                        label.current_print_time = label.date
                        let preview = self.previewLabel(label)
                        previews.push(preview)
                        labels.push(label)
                    }
                    self.previews = previews
                    self.labels = labels
                    if (previews.length === 0) {
                        self.error = self.$t('label.previous_labels.no_results')
                    }
                    if (typeof this.labels[0] !== 'undefined') {
                        let data = this.labels[0].data
                        this.$emit('sendValue', data)
                    }
                    self.$overlay.hide()
                })
                .catch((error) => {
                    logError(error)
                    self.error = self.$t('label.previous_labels.network_error')
                    self.$overlay.hide()
                })
        },
        sendValue(index) {
            this.selectedLabel = index
            let data = this.labels[index].data
            this.$emit('sendValue', data)
        },
        previewLabel(label) {
            let reference = this.$store.getters['label/getLabel'](label.product)
            let format = this.$store.getters['printer/getTemplates'](reference.states[label.state].format)
            let values = JSON.parse(format.data)

            if (label.preview) {
                try {
                    var params = JSON.parse(label.data)
                    let preview = JSON.parse(label.preview)

                    var oLocation = this.$store.getters['loginUser/getLocalLocation']
                    params['account'] = oLocation.account_name
                    params['location'] = oLocation.name
                    params['quantity'] = label.quantity
                    params['city'] = oLocation.city
                    params['address'] = oLocation.address
                    params['loc_state'] = oLocation.state
                    params['cp'] = oLocation.cp

                    for (let key in preview.fields) {
                        let field = preview.fields[key]
                        let value = typeof field.f_id !== 'undefined' ? values[field.f_id] : field.value
                        const regex = /[\{|\[][a-zA-Z0-9_\}|\]]*/gi
                        let m
                        let aux_value = value
                        while ((m = regex.exec(value)) !== null) {
                            // This is necessary to avoid infinite loops with zero-width matches
                            if (m.index === regex.lastIndex) {
                                regex.lastIndex++
                            }

                            // The result can be accessed through the `m`-variable.
                            m.forEach((match, groupIndex) => {
                                let m_index = match.replace(/[\{\}\[\]]/gi, '')
                                let name = this.$t('label.preview.' + m_index)

                                if (name.indexOf('label.preview.') != -1) {
                                    name = m_index
                                }

                                if (!Object.keys(params).includes(m_index)) {
                                    const regex2 = /(_d[0-9]|_day|_timestamp|_time)/gi
                                    let n
                                    while ((n = regex2.exec(m_index)) !== null) {
                                        // This is necessary to avoid infinite loops with zero-width matches
                                        if (n.m_index === regex2.lastIndex) {
                                            regex2.lastIndex++
                                        }

                                        // The result can be accessed through the `m`-variable.
                                        n.forEach((match2, groupIndex) => {
                                            let keyValue = m_index.replace(match2, '')
                                            if (Object.keys(params).includes(keyValue)) {
                                                let d = moment(params[keyValue], 'DD/MM/YYYY HH:mm')
                                                if (!d.isValid()) {
                                                    d = moment(parseInt(params[keyValue]) * 1000)
                                                }
                                                params[keyValue + '_day'] = d.format('DD/MM/YYYY')
                                                params[keyValue + '_time'] = d.format('HH:mm')
                                                params[keyValue + '_timestamp'] = parseInt(d.valueOf() / 1000).toString()
                                                params[keyValue + '_d1'] = d.isoWeekday() == 1 ? 'x' : ' '
                                                params[keyValue + '_d2'] = d.isoWeekday() == 2 ? 'x' : ' '
                                                params[keyValue + '_d3'] = d.isoWeekday() == 3 ? 'x' : ' '
                                                params[keyValue + '_d4'] = d.isoWeekday() == 4 ? 'x' : ' '
                                                params[keyValue + '_d5'] = d.isoWeekday() == 5 ? 'x' : ' '
                                                params[keyValue + '_d6'] = d.isoWeekday() == 6 ? 'x' : ' '
                                                params[keyValue + '_d7'] = d.isoWeekday() == 7 ? 'x' : ' '
                                            } else if (Object.keys(params).includes(keyValue + '_timestamp')) {
                                                let d = moment(parseInt(params[keyValue + '_timestamp']) * 1000)
                                                params[keyValue + '_day'] = d.format('DD/MM/YYYY')
                                                params[keyValue + '_time'] = d.format('HH:mm')
                                                params[keyValue] = d.format('DD/MM/YYYY HH:mm')
                                                params[keyValue + '_d1'] = d.isoWeekday() == 1 ? 'x' : ' '
                                                params[keyValue + '_d2'] = d.isoWeekday() == 2 ? 'x' : ' '
                                                params[keyValue + '_d3'] = d.isoWeekday() == 3 ? 'x' : ' '
                                                params[keyValue + '_d4'] = d.isoWeekday() == 4 ? 'x' : ' '
                                                params[keyValue + '_d5'] = d.isoWeekday() == 5 ? 'x' : ' '
                                                params[keyValue + '_d6'] = d.isoWeekday() == 6 ? 'x' : ' '
                                                params[keyValue + '_d7'] = d.isoWeekday() == 7 ? 'x' : ' '
                                            }
                                        })
                                    }

                                    if (!Object.keys(params).includes(m_index)) {
                                        params[m_index] = ''
                                    }
                                }

                                if (m_index != '' && Object.keys(params).includes(m_index)) {
                                    aux_value = aux_value.replace(match, params[m_index])
                                }
                            })
                        }
                        preview.fields[key].value = aux_value
                    }
                    this.$snackbar.close()
                    return preview
                } catch (e) {
                    this.$snackbar.error({ message: i18n.t('label.preview.invalid'), closeable: true })
                }
            } else {
                this.$snackbar.error({ message: i18n.t('label.preview.invalid'), closeable: true })
            }
        }
    },
    created() {
        this.related_states = typeof this.rules.related_states !== 'undefined' ? this.rules.related_states : []
        this.other_products = typeof this.state.info !== 'undefined' && typeof this.state.info.other_products !== 'undefined' ? this.state.info.other_products : []
        this.load()
    },
    mounted() {},
    watch: {}
}
</script>
<style lang="scss">
#previous_labels {
    @include display-flex();
    @include justify-content();
    @include align-items(center);
    @include flex-direction(column);
    width: 100%;
    height: 100%;

    .error {
        @include font-size(sm);
        @include border-radius(5px);
        font-family: $text;
        background-color: $default-sec-color;
        color: #07090f;
        padding: 18px;
    }
    .preview_container {
        margin: 0 auto;
        border: 4px solid transparent;
        cursor: pointer;
        width: max-content;

        &.selected .preview-container {
            border: 4px solid $main-dark;
            @include border-radius(4px);
        }
    }

    &.landscape {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-evenly;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 16px;

        &.no-results {
            grid-template-columns: auto;
        }
    }
}
</style>
