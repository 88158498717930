<template>
    <div class="column" :class="[$mq, clickEnabledMulti ? 'short' : '']" :style="{ width: computedWidth }">
        <header>
            <div class="text-title">
                <span :class="['quantity', data.color]">{{ sortedTimers.length }}</span>
                <span class="text">{{ data.name }}</span>
            </div>
            <div v-if="clickEnabledMulti" class="btn-select-all">
                <Button @on-button-click="data.selectAll ? sendDeselectAll(data) : sendSelectAll(data)" :contentClass="data.selectAll ? 'content-title-des' : 'content-title'" :bType="data.selectAll ? 'deselect-all' : 'select-all'" :bLabel="data.selectAll ? $t('button.deselect_all') : $t('button.select_all')" customClass="selector-title" />
            </div>
        </header>
        <div class="content">
            <Timer @clickTimer="clickTimer(timer.id)" @longPress="handleLongPress(timer.id)" v-for="timer in sortedTimers" :visibilityType="visibilityType" :key="timer.id" :data="timer" :clickEnabledMulti="clickEnabledMulti" @addTimerSelected="addTimerSelected(timer.id, data.id)"></Timer>
        </div>

        <footer v-if="clickEnabledMulti">
            <div class="footer-content">
                <div class="btn-delete">
                    <Button @on-button-click="deleteSelectAll()" v-if="clickEnabledMulti && data.order === 9999" contentClass="content-title" bType="delete-all" :bLabel="$t('button.delete')" customClass="selector-delete" />
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import Timer from '@/components/domain/timerproduct/timer.vue'
import { TIMERPRODUCT_STATUS } from '@/constants'
import Button from '@/components/domain/ui/Button.vue'

export default {
    components: { Timer, Button },
    props: {
        data: { type: Object },
        timers: { type: Array },
        visibilityType: { type: Number },
        countColumns: { type: Number },
        clickEnabledMulti: { type: Boolean }
    },
    data() {
        return {
            clickTimerIds: [],
            diffReturnValue: []
        }
    },
    computed: {
        computedWidth() {
            switch (this.countColumns) {
                case 2:
                    return '49.75%'
                case 3:
                    return '33.15%'
                case 4:
                    return '24.75%'
                default:
                    return '24.75%'
            }
        },
        sortedTimers() {
            return [...this.timers].sort((a, b) => {
                const aTime = this.getComparisonTime(a)
                const bTime = this.getComparisonTime(b)

                // Si visibilityType es 2 (timers vencidos), ordenar de mayor a menor tiempo transcurrido
                if (this.visibilityType === 2) {
                    return this.getTotalSeconds(bTime) - this.getTotalSeconds(aTime)
                }
                // Si visibilityType es 1 (timers activos), ordenar de menor a mayor tiempo restante
                else if (this.visibilityType === 1) {
                    return this.getTotalSeconds(aTime) - this.getTotalSeconds(bTime)
                }
                // Para cualquier otro caso, mantener el orden original
                return 0
            })
        }
    },
    methods: {
        clickTimer(id) {
            // console.log('clickTimer')
            this.$emit('clickTimer', id)
        },
        handleLongPress(id) {
            console.log('longPress', id)
            this.$emit('longPress', id)
        },
        addTimerSelected(id, column) {
            console.log('addTimerSelected', id)
            this.$emit('addTimerSelected', { timerId: id, columnId: column })
        },
        sendSelectAll(data) {
            if (!data) {
                console.error('Data is undefined or invalid')
                return
            }
            console.log('selectAllTimers', data)
            this.$emit('selectAllTimers', { column: data })
        },
        sendDeselectAll(data) {
            console.log('deselectAllTimers', data)
            this.$emit('selectAllTimers', { column: data, deselect: true })
        },
        deleteSelectAll() {
            console.log('deleteSelectAll')
            this.$emit('deleteSelectAll', true)
        },
        getComparisonTime(timer) {
            const now = moment()
            let diffReturn = null

            // Para timers activos
            if (this.visibilityType === 1) {
                let ref_date = timer.data.date_timestamp
                if (timer.status === TIMERPRODUCT_STATUS.PREPARATION) {
                    ref_date = timer.data.prep_date_timestamp
                }

                const duration = moment.duration(moment(ref_date * 1000).diff(now))
                let diff = duration.asSeconds()

                // Calcula tiempo restante
                let diff_days = parseInt(diff / 86400)
                diff = diff - diff_days * 86400
                let diff_hours = String(parseInt(diff / 3600)).padStart(2, '0')
                diff = diff - diff_hours * 3600
                let diff_minutes = String(parseInt(diff / 60)).padStart(2, '0')
                diff = diff - diff_minutes * 60
                let diff_seconds = String(parseInt(diff)).padStart(2, '0')

                diffReturn = {
                    d: diff_days,
                    h: parseInt(diff_hours),
                    m: parseInt(diff_minutes),
                    s: parseInt(diff_seconds)
                }
            }
            // Para timers vencidos
            else {
                const ref_date = timer.data.print_date_timestamp
                const duration = moment.duration(now.diff(moment(ref_date * 1000)))
                let diff = Math.abs(duration.asSeconds())

                // Calcula tiempo transcurrido desde vencimiento
                let diff_days = parseInt(diff / 86400)
                diff = diff - diff_days * 86400
                let diff_hours = String(parseInt(diff / 3600)).padStart(2, '0')
                diff = diff - diff_hours * 3600
                let diff_minutes = String(parseInt(diff / 60)).padStart(2, '0')
                diff = diff - diff_minutes * 60
                let diff_seconds = String(parseInt(diff)).padStart(2, '0')

                diffReturn = {
                    d: diff_days,
                    h: parseInt(diff_hours),
                    m: parseInt(diff_minutes),
                    s: parseInt(diff_seconds)
                }
            }

            return diffReturn
        },
        getTotalSeconds(time) {
            if (!time) return 0
            return time.d * 86400 + time.h * 3600 + time.m * 60 + time.s
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.column {
    flex: 0 0 auto;
    width: 33%;
    display: inline-block;
    vertical-align: top;
    height: 100%;
    overflow: hidden;

    &:last-child {
        margin-right: 0;
    }

    header {
        @include display-flex();
        @include justify-content(space-between);
        @include align-items(center);
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        text-align: left;
        width: 100%;
        height: 45px;
        cursor: pointer;
        padding: 5px;
        text-transform: uppercase;
        background-color: #ddd;

        .text-title {
            display: flex;
            align-items: center;

            .text {
                @include font-size(sm);
                font-family: $text;
                color: $neutro-s90;
                text-align: left;
                padding-left: 8px;
            }

            .quantity {
                background-color: $inactive;
                padding: 6px 8px;
                text-align: center;
                font-size: 18px;
                border-radius: 4px;
                font-family: Avenir-Bold;
                background-color: $color-error-500;
                color: #fff;
                margin-right: 8px;

                &.ok {
                    background-color: $done;
                }
                &.error {
                    background-color: $color-error-500;
                }
            }
        }

        .btn-select-all {
            margin-left: auto;
            margin-top: -23px;
            width: 166px;
        }
    }

    footer {
        @include display-flex();
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        width: 100%;
        height: 45px;
        padding: 5px;
        text-transform: uppercase;
        background-color: #ddd;

        .footer-content {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .btn-delete {
            margin-top: -21px;
        }
    }

    .content {
        background-color: #fff;
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 50% 50%;
        grid-auto-rows: min-content;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 4px;
        gap: 2px;
    }
    .content ::v-deep .timer {
        width: 98%;
        height: 120px;
        margin: 2px;
        padding: 2px;
        box-sizing: border-box;
    }

    &.short {
        .content {
            height: calc(100% - 90px);
        }
    }
}
</style>
