<template>
    <div class="stateLabel" :class="[{ portrait: ['portrait'].includes($mq) }, selectedState.stateID == stateID && selectedState.collectionID == collectionID && selectedState.referenceID == referenceID ? 'selected' : '', completed ? 'completed' : '']" @click="changeSelectedState()" :key="completed">
        <div class="image" :style="image != false ? { backgroundImage: 'url(' + image + ')' } : {}"></div>

        <div class="text">
            <div class="name">{{ name }}</div>
            <div class="status" v-if="!['portrait'].includes($mq)"># {{ state.toUpperCase() }}</div>
            <div class="status" v-else>{{ state.toUpperCase() }}</div>
        </div>

        <div class="information">
            <div class="status" :class="{ completed: completed }"></div>
            <div class="quantity">x{{ currentQuantity ? currentQuantity : quantity }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StateLabel',
    props: ['stateID', 'collectionID', 'referenceID', 'name', 'state', 'image', 'quantity', 'selectedState'],
    data() {
        return {}
    },
    computed: {
        completed() {
            let collections = this.$store.getters['label/getCollections']
            return collections[this.collectionID].references[this.referenceID].states[this.stateID].completed
        },

        currentQuantity() {
            let collections = this.$store.getters['label/getCollections']
            let collection = collections[this.collectionID].references[this.referenceID].states[this.stateID]
            return typeof collection.currentQuantity !== 'undefined' ? collection.currentQuantity : false
        }
    },
    created() {},
    methods: {
        changeSelectedState() {
            if (!this.completed) {
                this.$emit('changeSelectedState', {
                    stateID: this.stateID,
                    collectionID: this.collectionID,
                    referenceID: this.referenceID
                })
            }
        }
    }
}
</script>

<style lang="scss">
.stateLabel {
    @include interaction();
    @include display-flex();
    @include border-radius(5px);
    @include align-items();
    min-height: 40px;
    height: auto;
    padding: 6px;
    background-color: #fff;
    margin: 12px auto;

    @include bgHover(#fff);

    &:nth-child(1) {
        margin-top: 0;
    }

    &.selected {
        @include box-shadow($h: 0px, $v: 0px, $b: 0px, $s: 6px, $c: rgba(shade($default-bg-color, 6%), 1));

        .text .name {
            color: $main-s10;
        }
    }

    &.completed {
        cursor: initial;
        opacity: 0.6;
    }

    .image {
        @include background($image: img('offline/no_icon_light.svg'), $size: 40px, $position: center center);
        width: 50px;
        height: 50px;
        display: inline-block;
    }

    .text {
        @include display-inline-flex();
        flex-wrap: wrap;
        min-height: 24px;
        width: calc(100% - 50px - 30px);
        .name {
            @include font-size(m);
            font-family: $text-bold;
            width: 100%;
        }
        .status {
            @include font-size(sm);
            width: 100%;
        }
    }

    .information {
        @include display-inline-flex();
        @include flex-direction(column);
        @include flex-wrap(wrap);
        min-width: 20px;
        width: 30px;
        height: 100%;
        .status {
            width: 100%;
            height: 20px;
            &.completed {
                @include background($image: img('printing_main_s10.svg'), $size: 20px, $position: center center);
            }
        }
        .quantity {
            @include font-size(m);
            width: 100%;
            height: 20px;
            text-align: center;
            font-family: $conden-bold;
        }
    }
}
</style>

<style lang="scss">
.stateLabel {
    &.portrait {
        &.selected {
            background-color: $main;
            box-shadow: none;

            .text {
                .name {
                    color: #fff;
                }
                .status {
                    @include font-size(s);
                    font-family: $text;
                    color: #fff;
                }
            }
            .information {
                .quantity {
                    font-family: $text;
                    color: #fff;
                }
            }
        }
        .text {
            .name {
                @include text-ellipsis();
                max-width: 97%;
            }
            .status {
                @include font-size(xs);
                @include text-ellipsis();
                max-width: 97%;
            }
        }
    }
}
</style>
