<template>
    <div class="datetime-container" :class="$mq">
        <div class="max-exceeded" v-if="min" v-html="'La fecha y hora no puede ser anterior a: ' + now" :class="{ hide: !min }">{{ now }}</div>
        <template v-if="['portrait'].includes($mq)">
            <div class="select-container">
                <div class="item date" @click="bShowCalendar = true" :class="{ selected: bShowCalendar, pending: !calendarValue }">{{ $t('audit.headers.date') }}</div>
                <div class="item hour" @click="bShowCalendar = false" :class="{ selected: !bShowCalendar, pending: !hourValue2 }">{{ $tc('assets.timing.hour', 1) }}</div>
            </div>
            <div class="datePicker" v-show="bShowCalendar">
                <calendar-selector :givenValue="calendarValue" @input="calendarVal($event)" :minDate="minDate" :maxDate="maxDate" :pastDates="bPastDates" ref="Calendar"></calendar-selector>
            </div>

            <div class="timePicker" v-show="!bShowCalendar">
                <HourTimeSelector :show="show" @input="resultTime($event)" :givenValue="hourValue2"></HourTimeSelector>
            </div>
        </template>
        <template v-else>
            <div class="componentContainer">
                <calendar-selector :givenValue="calendarValue" @input="calendarVal($event)" :minDate="minDate" :maxDate="maxDate" :pastDates="bPastDates" ref="Calendar"></calendar-selector>
                <HourTimeSelector :show="show" @input="resultTime($event)" :givenValue="hourValue2"></HourTimeSelector>
            </div>
        </template>
    </div>
</template>

<script>
import HourTimeSelector from '@/components/inputs/HourTimeSelector'
import CalendarSelector from '@/components/inputs/CalendarSelector'

export default {
    components: { HourTimeSelector, CalendarSelector },
    props: {
        givenValue: String,
        show: Boolean,
        minDate: String,
        maxDate: String
    },
    data() {
        return {
            min: false,
            now: false,
            calendarValue: '',
            hourValue2: '',
            productKey: 1,
            bShowCalendar: true
        }
    },
    computed: {
        bPastDates() {
            return this.minDate == undefined
        }
    },
    methods: {
        load() {
            if (this.givenValue !== '') {
                const dateTime = this.givenValue.split(' ')
                const date = dateTime[0]
                const time = dateTime[1]
                this.calendarValue = date
                this.hourValue2 = time
            }
        },
        calendarVal(val) {
            this.calendarValue = val
            this.sendValue()
        },
        resultTime(event) {
            this.hourValue2 = event
            this.sendValue()
        },
        sendValue() {
            if (this.hourValue2 !== '' && this.calendarValue !== '') {
                var date = moment(this.calendarValue + ' ' + this.hourValue2)
                var dateNow = moment()
                var freeDate = this.minDate == undefined && this.maxDate == undefined

                if (date.valueOf() < dateNow.valueOf() && !freeDate && !this.bPastDates) {
                    this.now = dateNow.format('DD/MM/YYYY HH:mm')
                    if (this.min === false) {
                        this.min = true
                    }
                    this.$emit('input', '')
                } else {
                    this.min = false
                    this.$emit('input', date.format('DD/MM/YYYY HH:mm'))
                }
            } else {
                this.$emit('input', '')
            }
        }
    },
    mounted() {},
    updated() {},
    created() {
        this.load()
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.datetime-container {
    display: grid;
    @include justify-content();
    @include align-items(flex-start);
    width: 100%;
    text-align: center;
    margin-top: rem(18px);

    position: relative;

    .max-exceeded {
        @include border-radius(3px);
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include font-size(s);
        line-height: 1.2;
        text-align: center;
        color: #fff;
        background-color: $error;
        width: 100%;
        height: rem(40px);
        margin: 0 auto;
        padding: 10px;
        overflow: hidden;
        transition: 0.2s;

        &.hide {
            height: 0px;
            padding: 0;
        }
    }
    .componentContainer {
        display: flex;
    }
}

.calendar-container {
    min-height: rem(200px);
    width: auto;
}
.vscroller {
    margin-left: rem(42px);
    margin-top: rem(40px);
    // -ms-touch-action: none;
    // touch-action: none;
}

@include small {
    .calendar-container {
        min-height: rem(200px);
    }
    .vscroller {
        margin-left: rem(15px);
        margin-top: rem(25px);
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.datetime-container.portrait {
    .print-date {
        @include font-size(m);
        font-family: $text-sbold;
        color: $main;
        width: 100%;
        text-align: center;
    }
    .print-date-text {
        color: $neutro-s80;
        @include font-size(ml);
        font-family: $text-bold;
        padding-top: 10px;
        padding-bottom: 20px;
    }

    .select-container {
        @include display-flex();
        @include align-items();
        @include flex-direction(row);
        width: 100%;
        min-width: 100%;
        min-width: 90vw;
        max-width: 90vw;
        padding-bottom: 10px;
        margin: 0 auto;

        .item {
            @include font-size(s);
            font-family: $text-sbold;
            text-transform: uppercase;
            cursor: pointer;
            @include display-flex();
            @include align-items(center);
            @include justify-content(center);
            width: 100%;
            background-color: #fff;
            height: 45px;
            color: $neutro-s20;

            &.pending {
                @include background($image: img('alert_complem.svg'), $size: 15px, $position: left 15px center);
                background-color: #fff;
            }
            &.selected {
                font-family: $text-sbold;
                background-color: $neutro;
                color: $main-dark;
            }
        }
    }

    .datePicker {
        @include display-flex();
        @include justify-content();
        @include align-items();
        @include flex-wrap(wrap);
        height: 100%;
        width: auto;
        max-width: 50%;
        padding: 0 15px;
        min-width: 350px;
        // padding-bottom: 20px;
        margin: 0 auto;

        .calendar-container {
            width: 100%;
            height: 100%;
            margin-top: 0px;

            .vfc-styles-conditional-class {
                @include display-flex();
                @include align-items(center);
                width: 100%;
                height: 100%;

                .vfc-main-container {
                    @include border-radius(8px);
                    width: 100%;
                    height: auto !important;
                    padding: 15px;

                    .vfc-calendars {
                        width: 100%;
                        height: 100%;
                        background-color: #fff;

                        div.vfc-content {
                            background-color: #fff;
                            width: 100%;
                            height: auto;
                            margin: 0 !important;
                            z-index: 3 !important;

                            .vfc-marked {
                                background-color: $main-dark !important;
                            }

                            .vfc-top-date {
                                position: relative;
                                top: -8px;
                            }
                        }
                    }

                    .vfc-navigation-buttons {
                        height: 35px;
                        .vfc-cursor-pointer {
                            width: 35px;
                            height: 35px;
                            background-color: #eeeeee;

                            .vfc-arrow-right {
                                top: -8px;
                                right: 1px;
                            }
                            .vfc-arrow-left {
                                top: -8px;
                                left: 4px;
                            }
                            .vfc-disabled {
                                top: 0px;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
