<template>
    <div class="related-values" :class="$mq">
        <!-- <div class="title">{{ $t('label.related.last_values') }}</div> -->
        <div class="container">
            <div class="item-label" :class="{ portrait: ['portrait'].includes($mq) }" v-for="value in values" :key="value" @click="emitValue(value)">
                {{ formatValue(value) }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'relatedValues',
    props: {
        values: {
            type: Array,
            default: []
        },
        type: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    methods: {
        emitValue(value) {
            this.$emit('select', value)
        },
        formatValue(value) {
            let result = ''
            switch (this.type) {
                case 'string':
                    result = value
                    break
                case 'date':
                case 'datefree':
                case 'datepast':
                    const date = moment(value, 'DD/MM/YYYY HH:mm').format('DD/MM/YYYY')
                    result = date
                    break
                case 'datetime':
                case 'datetimefree':
                case 'datetimepast':
                    result = value
                    break
            }
            return result
        }
    },
    created() {}
}
</script>

<style lang="scss" scoped>
.related-values {
    width: 100%;

    .title {
        @include font-size(sm);
        color: $main;
        margin-bottom: 10px;
    }

    .container {
        padding: 0;

        .item-label {
            @include font-size(sm);
            @include border-radius(4px);
            @include text-ellipsis();

            display: inline-block;
            position: relative;
            height: 45px;
            line-height: 45px;
            padding: 0 15px;
            padding-right: 55px;
            background-color: $default-sec-color;
            cursor: pointer;
            overflow: hidden;
            width: fit-content;
            margin-bottom: 10px;
            margin-right: 10px;
            max-width: 300px;
            &:after {
                position: absolute;
                right: 0;
                top: 0;
                margin-left: 15px;
                display: inline-block;
                content: ' ';
                width: 45px;
                height: 45px;
                @include background($image: img('copy_neutro_s90.svg'), $position: center center, $size: 20px, $color: #fff);
            }
        }
    }

    &.landscape,
    &.desktop {
        .item-label {
            @include font-size(m);
            border: 1px solid $default-sec-color;
        }
    }
}
</style>
