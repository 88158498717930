<template>
    <div :id="`preview-container${_uid}`" :ref="`preview-container${_uid}`" :class="({ full: maximized }, 'preview-container')" :style="{ width: '100%', height: 'auto' }">
        <div id="preview" :ref="`preview${_uid}`" :class="{ full: maximized }" :style="{ width: preview.width * 7 + 'px', height: preview.height * 7 + 'px' }">
            <!-- <div v-if="loaded" id="preview" :class="{ full: maximized }" :style="{ width: '100%', height: 'auto' }"> -->
            <!-- {{ preview }} -->
            <div
                class="field"
                v-for="(field, i) in preview.fields"
                :key="i"
                :class="[field.classes, field.type, field.current && !maximized ? 'edit' : '']"
                :style="{
                    width: field.width + '%',
                    height: field.height_scale * 100 + '%'
                }"
            >
                <template v-if="field.type == LABEL_STEP_TYPE.TEXT">
                    <template v-if="field.fit">
                        <div class="textos" :class="{ editing: field.current && !maximized }" v-html="field.value"></div>
                    </template>
                    <template v-else>
                        <div class="textos" :class="{ editing: field.current && !maximized }" v-html="field.value"></div>
                    </template>
                </template>

                <template v-if="field.type == LABEL_STEP_TYPE.IMAGE">
                    <img :src="field.value" class="image" />
                </template>

                <template v-if="field.type == LABEL_STEP_TYPE.BARCODE">
                    <!-- <VueBarcode v-if="field.value" :value="field.value" class="barcode-show" :height="field.height" :format="barcodeConfig.format" width="3">
                        <span v-html="field.value"></span>
                    </VueBarcode> -->
                    <div v-if="field.value" class="qrcode">
                        <VueQRCodeComponent :text="field.value" :size="120" error-level="L" class="img"></VueQRCodeComponent>
                        <span class="value" v-html="field.value"></span>
                    </div>
                </template>

                <template v-if="field.type == LABEL_STEP_TYPE.DAYS">
                    <div v-for="day in days" :key="day.i" class="day-container">
                        <img src="~@/../public/img/circle.svg" class="circle-image" :class="{ selected: field.day == day.i }" />
                        <div class="day">{{ day.name }}</div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
import VueQRCodeComponent from 'vue-qr-generator'
import i18n from '@/i18n'

import { LABEL_STEP_TYPE } from '@/constants'

export default {
    components: { VueBarcode, VueQRCodeComponent },
    props: {
        data: { type: Object, default: false },
        maximized: { type: Boolean, default: false },
        previousLabels: { type: Boolean, default: false },
        calculate: { type: undefined, default: 0 },
        refresh: { type: Boolean, default: true }
    },
    name: 'RealisticPreview',
    data() {
        return {
            days: [
                { i: 1, name: i18n.t('calendar.day_monday').toUpperCase() },
                { i: 2, name: i18n.t('calendar.day_tuesday').toUpperCase() },
                { i: 3, name: i18n.t('calendar.day_wednesday').toUpperCase() },
                { i: 4, name: i18n.t('calendar.day_thursday').toUpperCase() },
                { i: 5, name: i18n.t('calendar.day_friday').toUpperCase() },
                { i: 6, name: i18n.t('calendar.day_saturday').toUpperCase() },
                { i: 0, name: i18n.t('calendar.day_sunday').toUpperCase() }
            ],
            LABEL_STEP_TYPE: LABEL_STEP_TYPE,
            default: {
                width: 55,
                height: 50,
                font: 'Helvetica'
            },
            default_size: 16,
            default_field: {
                width: 100,
                height: 30,
                value: false,
                type: 'text',
                align: 'l',
                style: ''
            },
            barcodeConfig: {
                format: 'CODE39',
                height: '35'
            },
            divsToFit: [],
            timer: false
        }
    },
    computed: {
        preview() {
            // this.data.height = 100
            // this.data.width = 100

            var rate = this.data.width / this.data.height

            // Calc sum of height of fields to scale global preview height
            var sum_height = 0
            var tmp_width = 0
            for (var field_id in this.data.fields) {
                var field = this.data.fields[field_id]
                field = { ...this.default_field, ...field }
                field.classes = []
                field.classes.push(field.align)
                if (field.style.includes('b')) field.classes.push('b')
                if (field.style.includes('u')) field.classes.push('u')
                if (field.style.includes('i')) field.classes.push('i')

                if (field.type == LABEL_STEP_TYPE.BARCODE) field.height = field.height ? field.height : 60
                if (field.type == LABEL_STEP_TYPE.DAYS) field.height = field.height ? field.height : 60
                if (field.type == LABEL_STEP_TYPE.IMAGE) field.height = field.height ? field.height : 60

                if (field.type == LABEL_STEP_TYPE.DAYS) {
                    field.day = field.value != '' ? moment(field.value * 1000).day() : moment().day()
                }

                this.data.fields[field_id] = field

                // Check if there is a new row (sum field width > 100%)
                tmp_width += field.width
                if (tmp_width >= 100) {
                    sum_height += field.height
                    if (tmp_width == 100) {
                        tmp_width = 0
                    } else {
                        tmp_width = field.width
                    }
                }
            }

            for (var field_id in this.data.fields) {
                var field = this.data.fields[field_id]
                field.height_scale = field.height / sum_height
                this.data.fields[field_id] = field
            }

            for (var field_id in this.data.fields) {
                var field = this.data.fields[field_id]
                if (field.type == LABEL_STEP_TYPE.TEXT) {
                    field.value = field.value.replace('undefined', '').replace('null', '').replace(null, '')
                }
            }

            return { ...this.default, ...this.data }
        }
    },
    methods: {
        // Metodo para detectar el espacio que tiene un div y saber si tiene overflow
        checkOverflow(e) {
            var isOverflowing
            if (e.clientHeight == this.default_field.height) {
                isOverflowing = e.clientWidth < e.scrollWidth || e.clientHeight < e.scrollHeight
                e.style.wordBreak = 'unset'
                e.style.lineHeight = 'unset'
                e.style.whiteSpace = 'nowrap'
            } else {
                isOverflowing = e.clientHeight < e.scrollHeight
                e.style.wordBreak = 'break-word'
                e.style.lineHeight = 'normal'
                e.style.whiteSpace = 'normal'
            }
            return isOverflowing
        },

        // Método que pone la fuente en 1px e incrementa todo lo que quepa en el div
        fitFontSize(e) {
            let step = 3
            let fitted = false
            let lastSize = 22

            e.style.fontSize = lastSize + 'px'

            // Check if text size must increse or decrease
            if (this.checkOverflow(e)) {
                step = step * -1
            }
            lastSize = lastSize + step
            e.style.fontSize = lastSize + 'px'

            // Iterate until is not overflow
            while (!fitted && lastSize > 0) {
                if (this.checkOverflow(e)) {
                    if (step > 0) {
                        // Increase
                        lastSize = lastSize - step
                        fitted = true
                    } else {
                        // Decrease
                        lastSize = lastSize + step
                    }
                } else {
                    if (step > 0) {
                        // Increase
                        lastSize = lastSize + step
                    } else {
                        // Decrease
                        fitted = true
                    }
                }

                e.style.fontSize = lastSize + 'px'
            }
        },
        calculatePreviewWidth(onlyDiv) {
            if (this.refresh) {
                var startTime = performance.now()
                if (!onlyDiv) {
                    // Formato preview
                    // var scale = largo del contenedor / largo de la etiqueta   --> pasar de milímetros a px
                    var preview = this.$refs[`preview${this._uid}`]
                    var container = this.$refs[`preview-container${this._uid}`]
                    var maxW = 455
                    var rate = this.data.width / this.data.height

                    if (this.maximized) {
                        var content = document.querySelector('#content')
                        var content_width = content.clientWidth * 0.95

                        var width = Math.min(maxW, content_width)

                        if (preview) {
                            preview.style.width = width + 'px'
                            preview.style.height = width / rate + 'px'
                        }

                        container.style.width = width + 'px'
                    } else if (this.previousLabels) {
                        var content = document.querySelector('#content')
                        if (['desktop', 'landscape'].includes(this.$mq)) {
                            var content_width = content.clientWidth * 0.3 > 300 ? 300 : content.clientWidth * 0.3
                        } else {
                            var content_width = content.clientWidth * 0.7
                        }

                        var width = Math.min(maxW, content_width)

                        if (preview) {
                            preview.style.width = width + 'px'
                            preview.style.height = width / rate + 'px'
                        }

                        container.style.width = width + 'px'
                    } else {
                        var column = document.querySelector('#content .column.right')
                        if (column) {
                            var container = document.querySelector('#content .preview-info')
                            var width = column.clientWidth
                            container.style.width = width + 'px'
                            if (preview) {
                                preview.style.width = width - 30 + 'px'
                                preview.style.height = width / rate + 'px'
                                preview.style.overflowY = 'auto'
                            }
                        }
                    }

                    // Responsiveness of field texts

                    this.divsToFit = document.querySelectorAll(`#preview-container${this._uid} .textos`)
                    for (var i = 0; i < this.divsToFit.length; i++) {
                        if (this.divsToFit[i].innerText != '') {
                            this.fitFontSize(this.divsToFit[i])
                        }
                    }
                } else {
                    this.divsToFit = document.querySelectorAll(`#preview-container${this._uid} .textos`)
                    for (var i = 0; i < this.divsToFit.length; i++) {
                        if (this.divsToFit[i].innerText != '') {
                            this.fitFontSize(this.divsToFit[i])
                        }
                    }
                }

                log('calculatePreviewWidth total time: ' + (performance.now() - startTime))
            }
        },
        update() {
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            var self = this
            this.timer = setTimeout(function () {
                self.calculatePreviewWidth(false)
            }, 250)
        },
        onResize(event) {
            this.update()
        }
    },
    mounted() {
        this.calculatePreviewWidth(false)

        // var self = this
        // Vue.nextTick(function() {
        //     console.log('mounted')
        //     this.calculatePreviewWidth(false)
        // })
    },
    created() {
        // Se ejectua cada vez que se gira y por lo tanto crea varios eventos resize. No los elimina
        window.addEventListener('resize', this.onResize)
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.onResize)
    },
    watch: {
        data: function () {
            var self = this
            Vue.nextTick(function () {
                self.calculatePreviewWidth(true)
            })
        },
        calculate: function (newVal, oldVal) {
            var self = this
            // ESPERAR A RENDERIZAR PARA CALCULAR TAMAÑO
            Vue.nextTick(function () {
                self.update()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.preview-container {
    padding: 0px;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
    @include border-radius(5px);
    @include box-shadow($h: 0px, $v: 0px, $b: 6px, $s: 0px, $c: rgba($neutro-s90, 0.25));
    background: #fff;

    &::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    #preview {
        color: #111;
        padding: 10px;
        position: relative;
        overflow: hidden;
        overflow-y: auto;

        &.full {
            overflow-y: auto;
        }

        .field {
            @include text-ellipsis();
            display: inline-block;
            height: 30px;
            line-height: 30px;
            float: left;
            text-size-adjust: 80%;
            white-space: nowrap;
            position: relative;

            &.b {
                .textos {
                    font-weight: bold;
                }
            }
            &.i {
                .textos {
                    font-style: italic;
                }
            }
            &.u {
                .textos {
                    text-decoration: underline;
                }
            }
            &.l {
                .textos {
                    @include justify-content(flex-start);
                    text-align: left;
                }
            }
            &.c {
                .textos {
                    @include justify-content();
                    text-align: center;
                }
            }
            &.r {
                .textos {
                    @include justify-content(flex-end);
                    text-align: right;
                }
            }
            &.text {
                position: relative;

                .textos {
                    @include display-flex();
                    @include align-items(center);
                    @include border-radius(4px);
                    width: 100%;
                    height: 100%;
                    overflow: hidden;
                    word-break: break-word;
                    line-height: normal;
                    position: relative;
                    border: 3px solid transparent;
                    padding-left: 2px;
                    display: block !important;
                    // white-space: nowrap;

                    &.editing {
                        @include border-radius(4px);
                        position: relative;
                        border: 3px solid $progress;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                    }
                    &.editing::after {
                        @include background($image: img('edit_ffffff.svg'), $color: $progress, $size: 10px);
                        content: '';
                        width: 20px;
                        top: -10px;
                        height: 20px;
                        right: -8px;
                        border-radius: 50%;
                        position: absolute;
                        z-index: 1;
                    }
                }
            }
            &.barcode {
                @include display-flex();
                width: 100%;
                height: 100%;

                &.edit {
                    @include border-radius(4px);
                    position: relative;
                    border: 3px solid $progress;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                }
                &.edit::after {
                    @include background($image: img('edit_ffffff.svg'), $color: $progress, $size: 10px);
                    content: '';
                    width: 20px;
                    top: -10px;
                    height: 20px;
                    right: -8px;
                    border-radius: 50%;
                    position: absolute;
                    z-index: 1;
                }
                .barcode-show {
                    @include display-flex();
                    @include flex-direction(column);
                }
                .qrcode {
                    height: 100%;
                    margin: 0 auto;
                    .img {
                        height: 80%;
                        position: relative;
                        text-align: center;
                        img {
                            margin: 0 auto;
                        }
                        canvas {
                            margin: 0 auto;
                            height: 100%;
                        }
                    }
                    .value {
                        height: 10%;
                        font-size: 10px;
                        text-align: center;
                        margin: 0 auto;
                        display: block;
                        // margin-top: -5%;
                    }
                }
            }

            &.image {
                height: 100%;
                padding: 0px 2px;
                -webkit-filter: grayscale(100%) contrast(300%);
                filter: grayscale(100%) contrast(300%);
                overflow: unset;

                img {
                    height: 100%;
                }
            }
            &.days {
                display: flex;
                width: 100%;
                padding: 5px 0;

                .day-container {
                    @include display-flex();
                    @include flex-direction(column);
                    width: 100%;
                    height: 100%;
                    text-align: center;
                    align-items: center;

                    .circle-image {
                        width: 60%;
                        height: 60%;

                        &.selected {
                            @include background($image: img('x_preview.svg'), $size: 40%, $position: center center);
                        }
                    }
                    .day {
                        width: 90%;
                        margin: 0 1px;
                        display: inline-block;
                        text-align: center;
                        // font-size: 15px;
                        position: relative;
                        line-height: 20px;
                    }
                }
            }
            &.edit {
                overflow: unset;
            }
            &.barcode {
                @include display-flex();
                opacity: 1;
                width: 100%;
                height: 100%;
            }
            .vue-barcode-element {
                height: 100%;
                width: 100%;
            }
        }
    }
}
</style>

<style lang="scss">
.preview-container .qrcode {
    img {
        margin: 0 auto;
        height: 100%;
        display: none !important;
    }
    canvas {
        margin: 0 auto;
        height: 100%;
        display: block !important;
    }
    .value {
        height: 15px;
        line-height: 15px;
    }
}
</style>
